.document-list {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}
.document-list__item {
  margin-bottom: 25px;
  padding-top: 10px;
  border-top: 1px solid #b1b4b6;
  list-style: none;
}
.document-list__item-title {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  display: inline-block;
}

.breadcrumbs__list-item{
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  display: inline-block;
}

.govuk-header{
  background-color: black;
} 

.govuk-link {
  color: #1d70b8;
}
.lead-paragraph {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1111111111;
  margin-top: 0;
  margin-bottom: 40px;
  max-width: 30em;
}
.govspeak {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
}
.govspeak p {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 15px;
  margin-bottom: 15px;
}
.manual-body {
  margin-top: 30px;
}
.breadcrumbs {
  border-bottom: 1px solid #b1b4b6;
  padding-bottom: 5px;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #0b0c0c;
  margin-top: 15px;
  margin-bottom: 10px;
}
.breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
