govspeak,
.gem-c-govspeak {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
}

.glossary {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  padding: 0 0 25px 0;
}
.intro {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  padding-bottom: 25px;
}
