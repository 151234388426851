.Header {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.Header > a {
  font-size: 20pt;
  font-weight: 500;
  padding: 10px;
  border-radius: 10px;
  border: solid 2px black;
  text-decoration: none;
  cursor: pointer;
}

.boxed-header {
  background: black;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //height: 40px;
}
.header-div {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.header-link {
  text-decoration: none;
  text-align: left;
}

.header-link:hover {
  text-decoration: underline;
  color: white;
}

.header-text {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-align: left;
}
