.govuk-header__navigation-item{
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    width: 12.5%;
    //transition: all 0.3s ease-in;
}

.govuk-header__navigation-item:hover{
    overflow:initial;
    text-overflow:clip;
    width: 100%;
    flex:1;
}

.govuk-header__content{
    display: flex;
    width:100%;
    padding-left: 0;
}

.govuk-header__navigation {
    width:100%;
}

.govuk-header__navigation-list {
    display: flex;
    width: 100%;
}