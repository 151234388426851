.gem-c-document-list {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

.gem-c-document-list__item {
  margin-bottom: 25px;
  padding-top: 10px;
  border-top: 1px solid #b1b4b6;
  list-style: none;
}

.gem-c-document-list__item-title {
  cursor: pointer;
  text-decoration: none;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.25;
  display: inline-block;
  color: #1d70b8;
}
.gem-c-document-list__item-title:visited {
  color: #4c2c92;
}
