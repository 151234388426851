.manuals-header {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  background: #1d70b8;
  color: #fff;
  margin: 0;
  padding: 1px 30px;
}
.grid-columns-two-thirds {
  width: 66.6666%;
}
.heading-1 {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.0416666667;
  padding: 0 0 15px;
}
.metadata {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  margin-bottom: 15px;
}
.in-manual-search {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  background: #1d70b8;
  color: #fff;
  margin: 0;
  //padding-bottom: 30px;
  //padding-top: 30px;
}
.search {
  position: relative;
  margin-bottom: 30px;
}
.search-label {
  position: absolute;
  font-size: 1.1875rem;
  line-height: 2.1052631579;
  font-weight: 400;
  left: 2px;
  top: 2px;
  bottom: 2px;
  padding-left: 15px;
  z-index: 1;
  color: #505a5f;
}
.search-item-wrapper {
  display: table;
  width: 100%;
  background: #fff;
  border: 2px solid #0b0c0c;
}
.search-input {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4736842105;
  margin: 0;
  width: 100%;
  height: 2.5em;
  padding: 0.375em;
  border: 2px solid #0b0c0c;
  background: #fff;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0;
}
.search-submit-wrapper {
  position: relative;
  display: table-cell;
  vertical-align: top;
  text-align: right;
  width: 1%;
}
.search-submit {
  background-color: #0b0c0c;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 0;
  position: relative;
  padding: 0;
  width: 40px;
  height: 40px;
  text-indent: -5000px;
  overflow: hidden;
}
.search-icon {
  display: block;
  pointer-events: none;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 10px;
  left: 10px;
}
