.content-h1 {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.09375;
  display: block;
  margin-top: 0;
  margin-bottom: 30px;
}

.content-h2 {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.1111111111;
  margin-top: 30px;
  margin-bottom: 0;
}

.content-h3 {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 35px;
  margin-bottom: 0;
}

.content-lead-p {
  color: #0b0c0c;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1111111111;
  margin-top: 0;
  margin-bottom: 15px;
  max-width: 30em;
}

.content-p {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 15px;
  margin-bottom: 15px;
}
.content-img-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.content-img {
  width: 50%;
  height: 50%;
  position: relative;
  object-fit: contain;
}

.content-ul {
  list-style: disc;
  list-style-position: outside;
  margin-left: 20px;
  padding: 0;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 15px;
  margin-bottom: 15px;
}
.content-odd-ul {
  list-style: circle;
}
.content-ul-3 {
  list-style: square;
}

.content-ol {
  list-style: decimal;
  list-style-position: outside;
  margin-left: 20px;
  padding: 0;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 15px;
  margin-bottom: 15px;
}
.content-li::marker {
  font-weight: 700;
}

#lower-roman {
  list-style-type: lower-roman;
}

#upper-roman {
  list-style-type: upper-roman;
}

.content-li {
  margin: 0 0 5px;
  padding: 0;
}

.content-nested-ol {
  list-style: lower-alpha;
  margin-left: 20px;
  padding: 0;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 15px;
  margin-bottom: 15px;
}

.content-nested-li::marker {
  font-weight: 700;
}

.content-code-container {
  font-size: 16px;
}
