.download_container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink:1;
}
.left_container {
  padding: 0 20px;
}

.right_container > a {
  cursor: pointer;
}
.download_details {
  font-size: 12px;
}
